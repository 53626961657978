import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostPreview from "../components/PostPreview"
import Reviews from "../components/Reviews"
import { getComments } from "../services/comments"
import profilePicture from "../images/kim-lindgren-profile.jpg"

const IndexPage = ({ data }) => {
  const { posts } = data.blog

  React.useEffect(() => {
    getComments()
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      <h2 className="border-b border-gray-900">Latest posts</h2>
      {posts.map(post => (
        <PostPreview post={post} key={post.id} />
      ))}
      <h2 className="mt-12 border-b border-gray-900 mb-4">Reviews</h2>
      <Reviews />
      <h2 className="mt-8 mb-4 border-b border-gray-900">About me</h2>
      <section className="flex flex-col-reverse sm:flex-row">
        <section className="sm:w-1/2">
          <p className="lg:col-span-3">My name is Kim Lindgren and I am a certified evolutionary astrologer based in Sweden.</p>
          <p>I offer <Link to="counseling">astrological consultations</Link> for anyone regardless of astrological interest. Whether you want help to interpret your birth chart or just want to gain perspective on your life without unnecessary astrological jargon - we will adapt the consultation to suit your needs.</p>
          <p>If you want to study EA but aren't sure where to start, feel free to <Link to="contact">contact me.</Link></p>
        </section>
        <section /*style={{ position: "relative", zIndex: "-1" }}*/ className="flex justify-center items-center sm:w-1/2">
          <img src={profilePicture} style={{ maxWidth: "275px" }} className="rounded-full"></img>
          {/* <StaticImage
            src="../images/kim-lindgren-profile.jpg"
            width={250}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Kim Lindgren"
            imgStyle={{ borderRadius: `100%`, marginBottom: `1rem`, float: `right` }}
            className="rounded-full mb-4"
          /> */}
        </section>
      </section>

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query excerptQuery {
    blog: allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: [DESC, DESC], fields: [frontmatter___date, slug] }
      limit: 3
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          title
          author
          tags
          language
          introduction
        }
        excerpt
        id
        timeToRead
      }
    }
  }
`
